<template>
  <div>
    <b-card-code>
      <validation-observer
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
        >
          <app-collapse
            accordion
            type="border"
          >
            <app-collapse-item
              title="Filtros"
              :is-visible="activeFilter"
            >
              <b-row>
                <b-col
                  sm="3"
                  xs="12"
                  md="4"
                >
                  <i2-form-vue-select
                    v-model="model.company"
                    name="company"
                    rules="required"
                    label="Empresa"
                    :options="companiesList"
                  />
                </b-col>
                <b-col
                  sm="3"
                  xs="12"
                  md="4"
                >
                  <i2-form-vue-select
                    v-model="model.unity"
                    name="unity"
                    rules="required"
                    label="Unidade"
                    :options="unitiesList"
                  />
                </b-col>
                <b-col
                  sm="3"
                  xs="12"
                  md="4"
                >
                  <i2-form-vue-select
                    v-model="model.well"
                    name="well"
                    rules="required"
                    label="Poço"
                    :options="wellList"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  sm="3"
                  xs="12"
                  md="3"
                >
                  <i2-form-input-date
                    v-model="model.start_date"
                    label="Data Inicial"
                    rules="required"
                    name="start_date"
                  />
                </b-col>
                <b-col
                  sm="3"
                  xs="12"
                  md="3"
                >
                  <i2-form-time-input
                    v-model="model.start_hour"
                    label="Hora Inicial"
                    placeholder="Hora inicial"
                    rules="required"
                    name="start_hour"
                  />
                </b-col>
                <b-col
                  sm="3"
                  xs="12"
                  md="3"
                >
                  <i2-form-input-date
                    v-model="model.end_date"
                    label="Data Final"
                    name="end_date"
                  />
                </b-col>
                <b-col
                  sm="3"
                  xs="12"
                  md="3"
                >
                  <i2-form-time-input
                    v-model="model.end_hour"
                    label="Hora Final"
                    placeholder="Hora Final"
                    name="end_hour"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  md="12"
                  sm="12"
                  xs="12"
                  style="text-align: left"
                >
                  <b-form-checkbox
                    v-model="model.group"
                    :checked="model.group"
                    class="custom-control-primary"
                    name="is-group"
                    switch
                  >
                    Agrupado por data
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  md="12"
                  sm="12"
                  xs="12"
                  style="text-align: right"
                >
                  <!-- Form Actions -->
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="submit"
                    class="mr-2"
                  >

                    <feather-icon
                      icon="SearchIcon"
                      class="mr-50"
                    />
                    Pesquisar
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="button"
                    @click="excel"
                  >
                    <font-awesome-icon icon="file-excel" />
                    Abrir em planilha
                  </b-button>
                </b-col>
              </b-row>
            </app-collapse-item>
          </app-collapse>
        </b-form>
      </validation-observer>
    </b-card-code>
    <b-row v-if="items.length > 0">
      <b-col md="12">
        <b-card no-body>
          <b-card-header>
            <div>
              <b-card-title class="mb-1">
                <span v-if="model.end_date === ''">
                  Consumo a partir de {{ model.start_date }}
                </span>
                <span v-if="model.end_date !== ''">
                  Consumo entre {{ model.start_date }} e {{ model.end_date }}
                </span>
              </b-card-title>
            </div>
          </b-card-header>

          <!-- chart -->
          <b-card-body>
            <chartjs-component-line-chart
              :height="400"
              :chart-data="graph.datasets"
              :options="options"
              :plugins="plugins"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card no-body>
          <b-card-header>
            <div>
              <b-card-title class="mb-1">
                <span v-if="model.end_date === ''">
                  Nível do poço  a partir de {{ model.start_date }}
                </span>
                <span v-if="model.end_date !== ''">
                  Nível do poço  entre {{ model.start_date }} e {{ model.end_date }}
                </span>
              </b-card-title>
            </div>
          </b-card-header>

          <!-- chart -->
          <b-card-body>
            <chartjs-component-line-chart
              :height="400"
              :chart-data="graph.level"
              :options="options"
              :plugins="plugins"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card no-body>
          <b-card-header>
            <div>
              <b-card-title class="mb-1">
                <span v-if="model.end_date === ''">
                  Nível da corrente a partir de {{ model.start_date }}
                </span>
                <span v-if="model.end_date !== ''">
                  Nível da corrente entre {{ model.start_date }} e {{ model.end_date }}
                </span>
              </b-card-title>
            </div>
          </b-card-header>

          <!-- chart -->
          <b-card-body>
            <chartjs-component-line-chart
              :height="400"
              :chart-data="graph.chain"
              :options="options"
              :plugins="plugins"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card no-body>
          <b-card-header>
            <div>
              <b-card-title class="mb-1">
                <span v-if="model.end_date === ''">
                  Horas trabalhadas a partir de {{ model.start_date }}
                </span>
                <span v-if="model.end_date !== ''">
                  Horas trabalhadas entre {{ model.start_date }} e {{ model.end_date }}
                </span>
              </b-card-title>
            </div>
          </b-card-header>

          <!-- chart -->
          <b-card-body>
            <chartjs-component-line-chart
              :height="400"
              :chart-data="graph.hour_meter"
              :options="options"
              :plugins="plugins"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="items.length > 0">
      <b-col md="12">
        <b-card no-body>
          <b-card-header>
            <div>
              <b-card-title class="mb-1">
                <span> Limite Outorgado: {{ grant_limit }} (m³) </span>
              </b-card-title>
            </div>
          </b-card-header>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="items.length > 0">
      <b-col md="12">
        <Grid
          :fields="fields"
          :items="items"
          edit-button-label="edit"
          new-button-label="new"
        >
          <template #cell(date)="data">
            {{ data.item.date | moment("DD/MM/YYYY") }}
          </template>
          <template #cell(hour)="data">
            {{ data.item.date | moment("HH:mm") }}
          </template>
        </Grid>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import I2FormVueSelect from '@/components/i2/forms/elements/I2FormVueSelect.vue'
import I2FormInputDate from '@/components/i2/forms/elements/I2FormInputDate.vue'
import I2FormTimeInput from '@/components/i2/forms/elements/I2FormTimeInput.vue'
import moment from 'moment-timezone'
import Grid from '@/components/i2/grid/Grid.vue'
import { $themeColors } from '@themeConfig'
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import ChartjsComponentLineChart from '../chartjs/charts-components/ChartjsComponentLineChart.vue'

export default {
  name: 'MonitoringTable',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BFormCheckbox,
    BCardTitle,
    AppCollapse,
    AppCollapseItem,
    Grid,
    ValidationObserver,
    I2FormVueSelect,
    I2FormInputDate,
    I2FormTimeInput,
    ChartjsComponentLineChart,
  },
  directives: {
    Ripple,
  },
  data() {
    let CharstepSize
    let Charmin
    let Charmax
    return {
      companiesList: [],
      unitiesList: [],
      wellList: [],
      activeFilter: true,
      model: {
        start_date: '',
        end_date: '',
        start_hour: '00:00',
        end_hour: '',
        group: false,
        company: {
          value: '',
          text: '',
        },
        unity: {
          value: '',
          text: '',
        },
        well: {
          value: '',
          text: '',
        },
        file: [],
      },
      grant_limit: '',
      fields: [
        {
          key: 'date',
          label: 'Data',
          class: 'w-10',
          stickyColumn: true,
          sortable: true,
        },
        {
          key: 'hour',
          label: 'Hora',
          class: 'w-10',
          stickyColumn: true,
        },
        {
          key: 'volume',
          label: 'Consumo (litros)',
          class: 'w-20',
          sortable: true,
        },
        {
          key: 'nivel_agua',
          label: 'Nível d’água no poço (m)',
          class: 'w-20',
          sortable: true,
        },
        {
          key: 'chain',
          label: 'Corrente (A)',
          class: 'w-20',
          sortable: true,
        },
      ],
      items: [],
      graph: {
        datasets: {
          labels: [],
          datasets: [],
        },
        level: {
          labels: [],
          datasets: [],
        },
        chain: {
          labels: [],
          datasets: [],
        },
        hour_meter: {
          labels: [],
          datasets: [],
        },
      },
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: false,
        hover: {
          mode: 'label',
          animationDuration: 0,
        },
        animation: {
          duration: 0,
        },
        responsiveAnimationDuration: 0,
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          backgroundColor: '#CCC',
          titleFontColor: '#000',
          bodyFontColor: '#000',
        },
        layout: {
          padding: {
            top: -15,
            bottom: -25,
            left: -15,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              gridLines: {
                display: true,
                color: 'rgba(18,18,20,0.2)',
                zeroLineColor: 'rgba(18,18,20,0.2)',
              },
              ticks: {
                fontColor: '#121214',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                stepSize: CharstepSize,
                min: Charmin,
                max: Charmax,
                fontColor: '#121214',
              },
              gridLines: {
                display: true,
                color: 'rgba(18,18,20,0.2)',
                zeroLineColor: 'rgba(18,18,20,0.2)',
              },
            },
          ],
        },
        legend: {
          position: 'top',
          align: 'start',
          labels: {
            usePointStyle: true,
            padding: 25,
            boxWidth: 9,
          },
        },
      },
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'model.company': async function (data) {
      this.model.unity = {
        value: '',
        text: '',
      }
      if (this.model.company.value !== '') {
        const unities = await this.getUnities(data.value)
        if (unities.length > 0) {
          this.unitiesList = unities.map(item => ({
            value: item.uuid,
            text: item.name,
            wells: item.wells,
          }))
        }
      }
    },
    // eslint-disable-next-line func-names
    'model.unity': async function () {
      this.model.well = {
        value: '',
        text: '',
      }
      if (this.model.unity.value !== '') {
        this.wellList = this.model.unity.wells.map(item => ({
          value: item.uuid,
          text: item.name,
        }))
      }
    },
  },
  created() {
    this.model.start_date = moment().startOf('month').format('DD/MM/YYYY')
    this.model.end_date = moment().endOf('month').format('DD/MM/YYYY')
  },
  async mounted() {
    const companies = await this.getCompanies()
    this.companiesList = companies.map(item => ({
      value: item.uuid,
      text: item.name,
    }))
  },
  methods: {
    ...mapActions('company', ['getCompanies', 'getUnities']),
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          const startDate = `${this.model.start_date} ${this.model.start_hour}`
          const from = moment(startDate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')
          let to = ''
          if (this.model.end_date !== '') {
            let endDate = moment(`${this.model.end_date} 23:59`, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')

            if (this.model.end_hour !== '') {
              endDate = moment(`${this.model.end_date} ${this.model.end_hour}`,
                'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')
            }
            to = `&to=${endDate}`
          }

          const response = await this.$http.get(`readings/reports/pit/${this.model.well.value}?from=${from}${to}`)
          if (response.error) {
            this.$notify.error(response.error_message)
            return false
          }
          if (response.length === 0) {
            this.$notify.error('Não há dados para os filtros selecionados.')
          }
          this.items = response
          const graphVolume = []
          const graphGrantLimit = []
          const graphLevel = []
          const graphChain = []
          const graphPumpRunTime = []
          const graphHourMeter = []
          this.graph.datasets = {
            labels: [],
            datasets: [],
          }
          this.graph.level = {
            labels: [],
            datasets: [],
          }
          this.graph.chain = {
            labels: [],
            datasets: [],
          }
          this.graph.hour_meter = {
            labels: [],
            datasets: [],
          }
          this.items.map(val => {
            const date = moment(val.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
            this.graph.datasets.labels.push(date)
            this.graph.level.labels.push(date)
            this.graph.chain.labels.push(date)
            this.graph.hour_meter.labels.push(date)
            graphGrantLimit.push(val.grant_limit)
            graphVolume.push(val.volume)
            graphLevel.push(val.nivel_agua)
            graphChain.push(val.chain)
            graphPumpRunTime.push(val.pump_run_time)
            graphHourMeter.push(val.horas_trabalhadas)
            this.grant_limit = val.grant_limit

            return val
          })
          const dataSetGrant = {
            data: graphGrantLimit,
            label: 'Limite diário de consumo outorgado (litros)',
            borderColor: '#D62828',
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: '#D62828',
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: '#D62828',
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: 'rgba(0, 0, 0, 0.25)',
          }

          const dataSetPumpRunTime = {
            data: graphPumpRunTime,
            label: 'Limite diário de funcionamento da Bomba (horas)',
            borderColor: '#D62828',
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: '#D62828',
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: '#D62828',
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: 'rgba(0, 0, 0, 0.25)',
          }

          const datasetVolume = { ...dataSetGrant }
          datasetVolume.data = graphVolume
          datasetVolume.borderColor = '#2E86DE'
          datasetVolume.label = 'Consumo (litros)'
          datasetVolume.backgroundColor = '#2E86DE'
          datasetVolume.pointHoverBackgroundColor = '#2E86DE'
          this.graph.datasets.datasets.push(dataSetGrant, datasetVolume)
          this.activeFilter = false

          const datasetLevel = { ...dataSetGrant }
          datasetLevel.data = graphLevel
          datasetLevel.borderColor = '#2E86DE'
          datasetLevel.label = 'Nível do Poço'
          datasetLevel.backgroundColor = '#2E86DE'
          datasetLevel.pointHoverBackgroundColor = '#2E86DE'
          this.graph.level.datasets.push(datasetLevel)

          const datasetChain = { ...dataSetGrant }
          datasetChain.data = graphChain
          datasetChain.borderColor = '#2E86DE'
          datasetChain.label = 'Corrente elétrica da bomba (A)'
          datasetChain.backgroundColor = '#2E86DE'
          datasetChain.pointHoverBackgroundColor = '#2E86DE'
          this.graph.chain.datasets.push(datasetChain)

          const datasetHourMeter = { ...dataSetGrant }
          datasetHourMeter.data = graphHourMeter
          datasetHourMeter.borderColor = '#2E86DE'
          datasetHourMeter.label = 'Horas trabalhadas (H)'
          datasetHourMeter.backgroundColor = '#2E86DE'
          datasetHourMeter.pointHoverBackgroundColor = '#2E86DE'
          this.graph.hour_meter.datasets.push(dataSetPumpRunTime, datasetHourMeter)
        }
        return true
      })
    },
    excel() {
      this.$refs.refFormObserver.validate()
        .then(async success => {
          if (success) {
            const startDate = `${this.model.start_date} ${this.model.start_hour}`
            const from = moment(startDate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')
            let to = ''
            if (this.model.end_date !== '') {
              let endDate = moment(`${this.model.end_date} 23:59`, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')

              if (this.model.end_hour !== '') {
                endDate = moment(`${this.model.end_date} ${this.model.end_hour}`,
                  'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')
              }
              to = `&to=${endDate}`
            }
            const response = await this.$http.get(
              `readings/reports/pit/${this.model.well.value}?from=${from}${to}&format=xls&group=${this.model.group}`,
              { responseType: 'blob' },
            )
            if (!response) {
              this.$notify.error(response.error_message)
              return false
            }
            const file = window.URL.createObjectURL(response)
            const a = document.createElement('a')
            a.href = file
            if (this.model.group) {
              a.download = 'tabela_monitoramento_dados_agrupados.xlsx'
            } else {
              a.download = 'tabela_monitoramento_dados_nao_agrupados.xlsx'
            }
            document.body.appendChild(a)
            a.click()
            a.remove()
          }
          return true
        })
      return true
    },
  },
}
</script>

<style scoped>

</style>
